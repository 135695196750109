<template>
  <main class="bg-static-info-low">
    <RevContainer>
      <div class="grid-standard py-72 md:pb-[140px] md:pt-[124px]">
        <div class="col-span-full md:col-span-2 lg:col-start-2 lg:col-end-8">
          <header class="mb-7 flex flex-col-reverse md:mb-6">
            <h1
              v-if="!isBackmarketWarrantyClaim"
              class="heading-1"
              data-qa="title-confirm"
            >
              {{ i18n(translations.heading) }}
            </h1>

            <h1
              v-if="isBackmarketWarrantyClaim"
              class="heading-1"
              data-qa="title-confirm"
            >
              {{ i18n(translations.headingBm) }}
            </h1>

            <h2 class="text-static-default-low body-1">
              {{ i18n(translations.subHeading) }}
            </h2>
          </header>

          <p v-if="!isBackmarketWarrantyClaim" class="body-1 mb-7 md:mb-6">
            <FormattedMessage :definition="translations.contactUs">
              <template #answerDuration>
                <span class="whitespace-nowrap">
                  {{
                    i18n(translations.expectedDuration, {
                      duration: 1,
                    })
                  }}
                </span>
              </template>

              <template #appearDuration>
                <span class="whitespace-nowrap">
                  {{
                    i18n(translations.expectedDuration, {
                      duration: 2,
                    })
                  }}
                </span>
              </template>
            </FormattedMessage>
          </p>

          <p v-if="isBackmarketWarrantyClaim" class="body-1 mb-7 md:mb-6">
            <FormattedMessage :definition="translations.expectedAnswer">
              <template #duration>
                <span class="whitespace-nowrap">
                  {{
                    i18n(translations.expectedDuration, {
                      duration: 1,
                    })
                  }}
                </span>
              </template>
            </FormattedMessage>
          </p>

          <footer class="mt-24 flex flex-col gap-12 md:flex-row">
            <RevButton
              data-qa="see-conversation"
              full-width="adaptive"
              :to="conversationRoute"
              variant="primary"
            >
              {{ i18n(translations.seeConversation) }}
            </RevButton>

            <RevButton
              class="mt-3 md:mr-4 md:mt-0"
              data-qa="back-to-my-orders"
              full-width="adaptive"
              :to="myOrdersRoute"
              variant="secondary"
            >
              {{ i18n(translations.backToMyOrders) }}
            </RevButton>
          </footer>
        </div>

        <RevIllustration
          alt=""
          class="col-span-full self-center justify-self-center max-lg:mb-56 max-md:order-first md:col-span-2 lg:col-start-9 lg:col-end-12"
          :height="180"
          src="/img/common/HelpCenterZealot.svg"
          :width="200"
        />
      </div>
    </RevContainer>
  </main>
</template>

<script setup lang="ts">
import { navigateTo, useRoute, useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import { CUSTOMER_CONVERSATION } from '~/scopes/customer-conversation/route-names'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import { useResolution } from '../composables/useResolution'

import translations from './ContactConfirmationPage.translations'

const route = useRoute()
const { data: resolution, error } = await useResolution({
  resolutionId: route.params.resolutionId as string,
})

if (error.value) {
  await navigateTo({
    name: DASHBOARD.MY_ORDERS.SELF,
  })
}

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const myOrdersRoute = resolveLocalizedRoute({
  name: DASHBOARD.MY_ORDERS.SELF,
})

const conversationRoute = resolveLocalizedRoute({
  name: CUSTOMER_CONVERSATION.RESOLUTION,
  params: {
    resolutionId: route.params.resolutionId,
  },
})

const isBackmarketWarrantyClaim = computed(
  () => !!resolution.value?.resolution?.externalId,
)
</script>
