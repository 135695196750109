export default {
  backToMyOrders: {
    id: 'dashboard_diagnosis_contact_confirmation_back_my_orders',
    defaultMessage: 'Back to Orders',
  },
  contactUs: {
    id: 'dashboard_diagnosis_contact_confirmation_contact',
    defaultMessage:
      "You should hear back from the refurbisher within {answerDuration, html}. If you don't, we've got your back! Contact us using the \"SOS\" button in your conversation. It will appear after {appearDuration, html} if the refurbisher doesn't reply.",
  },
  heading: {
    id: 'dashboard_diagnosis_contact_confirmation_heading_merchant',
    defaultMessage: 'We delivered your message',
  },
  headingBm: {
    id: 'dashboard_diagnosis_contact_confirmation_heading_bm',
    defaultMessage: 'We delivered your message',
  },
  expectedAnswer: {
    id: 'dashboard_diagnosis_contact_confirmation_expected_answer_bm',
    defaultMessage:
      'Within {duration, html}, you’ll get an email with a link to our chat platform — use this to communicate with us about your request.',
  },
  expectedAnswerReplacement: {
    id: 'dashboard_diagnosis_contact_expected_answer',
    defaultMessage:
      'You should hear back from the refurbisher within {duration, html}.',
  },
  expectedDuration: {
    id: 'dashboard_diagnosis_contact_confirmation_expected_durations',
    defaultMessage: `{duration, number} {duration, plural,
      one {business day}
      other {business days}
    }`,
  },
  seeConversation: {
    id: 'dashboard_diagnosis_contact_confirmation_see_conversation',
    defaultMessage: 'See conversation',
  },
  subHeading: {
    id: 'dashboard_diagnosis_contact_confirmation_sub_heading',
    defaultMessage: 'Thanks!',
  },
}
